import React from "react";
import { Stack } from "basis";
import styled from "styled-components";

import { COUNTRY } from "../core/constants";
import { assetsBaseUrl } from "../core/config";

const StyledImage = styled.img`
  height: ${({ isVertical }) => (isVertical ? "55px" : "35px")};
  margin-right: 5px;
  display: block;
`;

export default ({ country = COUNTRY.AU, showAppleAUCreditLine }) => {
  let logos =
    country === COUNTRY.NZ
      ? ["GemVisaNZ_Vertical.png", "CreditlineNZ.png"]
      : ["gem.png", "Go.png", "Creditline.png", "BuyersEdge.png"];

  if (showAppleAUCreditLine) {
    logos = [...logos, "Creditline_AFS.png"];
  }

  return (
    <Stack data-testid={country} gap="2" direction="horizontal">
      <div style={{ display: "flex", alignItems: "center" }}>
        {logos.map((logo) => (
          <StyledImage
            key={logo}
            src={`${assetsBaseUrl}/latitude/acceptedCard/${logo}`}
            isVertical={logo.includes("Vertical")}
          />
        ))}
      </div>
    </Stack>
  );
};
