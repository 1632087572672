import React from "react";
import { Container, Stack, Text, Flex, Link } from "basis";

import { Icon } from "../";
import { appUrl } from "../../core/config";
import { getErrorDetails } from "./utils";
import { ERROR_CODES } from "./constants";

const ErrorMessage = ({
  type = ERROR_CODES.DEFAULT,
  title,
  description,
  additionalParams = {},
  test = false,
}) => {
  let error = { title, description };

  if (!title || !description) {
    error = getErrorDetails(type, additionalParams);
  }

  return (
    <Container
      padding="12 0 0 0"
      hasBreakpointWidth
      testId={`error-message-${type}`}
    >
      <Stack align="center" gap="4">
        <Text align="center" textStyle="heading3">
          {error?.title}
        </Text>

        <Text textStyle="subtitle1" align="center">
          {description ?? error?.description}
        </Text>

        <Container padding="8 0">
          <Flex placeItems="center">
            {error && error?.iconType && !test && (
              <Icon type={error?.iconType} size="200px" />
            )}
          </Flex>
        </Container>

        {additionalParams?.transactionId && (
          <Text textStyle="subtitle2" align="center">
            Ref: {additionalParams?.transactionId}
          </Text>
        )}

        {additionalParams?.urlCancel && additionalParams?.urlCancel !== appUrl && (
          <Link
            margin="0 0 6 0"
            appearance="primary-button"
            variant="blue-button"
            href={additionalParams?.urlCancel}
            newTab={false}
          >
            {additionalParams?.urlCancelLabel ?? "Return to cart"}
          </Link>
        )}

        {error?.addt && <Text align="center">{error.addt}</Text>}
      </Stack>
    </Container>
  );
};

export { ErrorMessage, ERROR_CODES };
